import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { NzModalService } from 'ng-zorro-antd';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Globals } from 'src/app/_configs/globals';
import { environment } from '../../../../environments/environment';
import { RideauNotificationService } from '../../../shared/services/rideau-notification.service';
import { Organization, IMember } from '../../organization/model/organization.model';
import { OrganizationService } from '../../organization/services/organization.service';
import { AccountService, UpdateAccountBody } from '../services/account.service';
import { AuthService } from '../services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RideauError } from 'src/app/shared/model/error.model';
import { FieldAreEqualsValidator } from 'src/app/shared/validators/fieldsAreEquals.validator';
import { filter, tap, skip, takeUntil, catchError } from 'rxjs/operators';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { OrganizationManagerService } from '../../organization/services/organizations-manager.service';

const SCENE_PRO_ID = 1;

@Component({
    selector: 'app-modify-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
    public openedTab: string;
    public checked: boolean;
    public isReachableChecked: any;
    public emailsOption: boolean;
    public uri: string = environment.BACKEND;
    public uploadAction = this.uri + 'upload/profileImage';
    public resetPasswordUrl = this.global.endpoints.reset_password;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';

    newEmailRequested = false;
    currentEmailToShow = '';
    showEmailPasswordField = false;
    validateForm: FormGroup;
    passwordForm: FormGroup;
    showPasswordForm = false;
    user: PxUser;
    userOrganizations$: Observable<Organization[]> = this.organizationManager.userOrganizations$;
    selectedUserOrganization$: Observable<Organization> = this.organizationManager.selectedUserOrganization$;
    organizations: Organization[] = [];
    organizationsNumber: number;
    currentLang = this.translate.currentLang;

    // avatar
    avatarUrl: string;
    fileInput: any = undefined;
    loading = false;

    canLeaveOrg: {
        id: number;
        value: boolean;
    }[] = [];

    isVisible = false;
    public readonly imageConfig = this.global.images;
    private destroyed: Subject<void> = new Subject();
    constructor(
        private router: Router,
        private fb: FormBuilder,
        protected global: Globals,
        private route: ActivatedRoute,
        private modalService: NzModalService,
        private authService: AuthService,
        public translate: TranslateService,
        public accountService: AccountService,
        public localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private organizationService: OrganizationService,
        private organizationManager: OrganizationManagerService
    ) {}

    ngOnInit(): void {
        this.user = this.authService.User;
        this.routeDataHandler();
        this.newEmailRequested = this.user.pendingEmailChangeRequest === 1;
        this.isReachableChecked = this.user.isReachable === 1;
        this.emailsOption = this.user.emailsOption === 1;
        this.initProfileFormGroup();
        this.initPasswordForm();
        this.selectedUserOrganization$
            .pipe(
                skip(1),
                tap((organization: Organization) => this.navigateToOrganization(organization.id)),
                takeUntil(this.destroyed)
            )
            .subscribe(
                () => {
                    //
                },
                (error: any) => console.log(error)
            );
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
    private updateCanLeaveOrg = (id: number) => {
        this.organizationService.getOrganizationTeam(id).subscribe((orgTeam) => {
            this.canLeaveOrg.push({
                id,
                value: !!orgTeam.members.find((member: IMember) => member.userId !== Number(this.user.id) && member.roleId === 1)
            });
        });
    };

    private routeDataHandler = () => {
        combineLatest([this.route.data, this.userOrganizations$])
            .pipe(
                filter(([route, _organizations]) => this.openedTab !== route.openedTab),
                tap(([route, _organizations]) => (this.openedTab = route.openedTab)),
                filter(([route, _organizations]) => route.openedTab === 'organizations'),
                tap(([_route, organizations]) => {
                    organizations.forEach((organization: Organization) => this.updateCanLeaveOrg(organization.id));
                })
            )
            .subscribe(
                () => {
                    //
                },
                (error: any) => console.log(error)
            );
    };

    public getCanLeaveOrg(organizationId: number): boolean {
        if (this.accountService.getCurrentCtxOrganizationId() === SCENE_PRO_ID) {
            return true;
        }
        const organization = this.canLeaveOrg.find((org) => org.id === organizationId);
        return organization ? organization.value : null;
    }

    /**
     * Update account information with a form submission.
     * @returns {void}
     */
    updateAccount(): void {
        this.updateFormValues();

        if (this.validateForm.dirty && this.validateForm.valid) {
            const isEmailChanged = this.isEmailChanged();
            const body: UpdateAccountBody = {
                ...this.validateForm.value,
                isEmailChanged: isEmailChanged ? 1 : 0,
                password: isEmailChanged ? this.validateForm.get('password').value : null
            };
            this.accountService
                .updateAccount(body)
                .pipe(
                    tap(() => {
                        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
                        if (isEmailChanged) {
                            this.handleEmailChange();
                        }
                    }),
                    catchError((error) => {
                        this.handleAccountUpdateError(error);
                        return throwError(error);
                    })
                )
                .subscribe();
        } else if (this.validateForm.dirty) {
            this.handleInvalidForm();
        }
    }

    updatePassword(): void {
        if (this.showPasswordForm && this.passwordForm.valid) {
            this.accountService
                .updatePassword(+this.user.id, {
                    oldPassword: this.passwordForm.get('currentPassword').value,
                    newPassword: this.passwordForm.get('newPassword').value
                })
                .subscribe(
                    () => {
                        this.showPasswordForm = false;
                        this.initPasswordForm();
                        this.notification.success(this.translate.instant('SUCCESS_RESET_PASSWORD'));
                    },
                    (error) => {
                        if (error.error.message && error.error.message == 'You have entered an invalid old password value') {
                            this.notification.error(this.translate.instant('ERRORS.WRONG-CURRENT-PASSWORD'));
                        } else {
                            throw error;
                        }
                    }
                );
        } else if (this.showPasswordForm) {
            this.notification.error('Formulaire de mot de passe non valide');
        }
    }

    /*FORM SUBMITS AND VALIDATIONS*/
    submitForm(): void {
        // update account information
        this.updateAccount();

        // update password
        this.updatePassword();
    }

    public createOrganizationHandler(): void {
        this.organizationService
            .createNewOrganization()
            .pipe(
                filter((organization: Organization) => !!organization),
                tap((organization: Organization) => this.organizationManager.addUserOrganization(organization)),
                tap(({ id }: Organization) => this.selectOrganization(null, id)),
                tap(() => this.organizationManager.setUserOrganizationStatus('created')),
                tap(() => this.notification.success(this.translate.instant('NOTIFICATIONS.ORGANIZATION-ADDED')))
            )
            .subscribe(
                () => {
                    //
                },
                (error: any) => console.log(error)
            );
    }

    public selectOrganization(event: PointerEvent, orgId: number): void {
        if (event) {
            event.stopPropagation();
        }
        this.accountService.setCurrentCtxOrganizationId(orgId);
    }

    private navigateToOrganization = (id: number): void => {
        const transTab: any[] = this.localizeRouter.translateRoute(['/organization/', id, 'modify', 'coordonnees']) as any[];
        setTimeout(() => this.router.navigate([...transTab]), 0);
    };

    leaveOrganization(organization: Organization): void {
        this.modalService.confirm({
            nzContent: this.translate.instant('SUPPRIMER-CONFIRMATION'),
            nzOkText: this.translate.instant('OUI'),
            nzCancelText: this.translate.instant('NON'),
            nzClosable: true,
            nzMaskClosable: true,
            // DELETE SERVICE CALL
            nzOnOk: () => {
                let orgMember: IMember;
                this.organizationService.getOrganizationTeam(organization.id).subscribe((orgTeam) => {
                    orgMember = orgTeam.members.find((member: IMember) => member.userId === Number(this.user.id));

                    this.organizationService.removeUserFromOrganization(Number(this.user.id), orgMember.id).subscribe(
                        () => {
                            this.organizationService.organizationsChanged.emit();
                            this.submitForm();
                        },
                        (error: HttpErrorResponse) => {
                            try {
                                const { name, statusCode, message, stack } = error.error;
                                const serverError: RideauError = new RideauError(name, statusCode, message, stack);
                                if (name === 'Forbidden') {
                                    this.notification.error(this.translate.instant('ERRORS.' + 'FORBIDDEN-MEMBER-DELETION-MEETING'), '');
                                    console.error('Error : ', serverError);
                                } else {
                                    throw error;
                                }
                            } catch (e) {
                                this.notification.error(this.translate.instant('ERRORS.SERVER-ERROR'), '');
                                console.error('Error : ', error);
                            }
                        }
                    );
                });
            }
        });
    }

    onUploadFileCallback(url: string): void {
        this.validateForm.get('avatar').setValue(url);
        this.validateForm.markAsDirty();
    }

    extensionChanged(value: string): void {
        this.validateForm.get('phoneExtensionNumber').setValue(value);
    }

    private initProfileFormGroup() {
        this.currentEmailToShow = this.newEmailRequested ? this.user.newEmail : this.user.email;

        this.validateForm = this.fb.group({
            id: this.user.id,
            firstName: new FormControl(this.user.firstName, [Validators.required]),
            lastName: new FormControl(this.user.lastName, [Validators.required]),
            email: new FormControl(this.currentEmailToShow, [Validators.required, Validators.pattern(this.global.emailRegex)]),
            emailsOption: new FormControl(this.user.emailsOption),
            phone: new FormControl(this.user.phone),
            phonePostNumber: new FormControl(this.user.phonePostNumber),
            avatar: new FormControl(this.user.avatar),
            password: new FormControl('')
        });

        this.initEmailValueChange();
    }

    private initPasswordForm(): void {
        this.passwordForm = this.fb.group(
            {
                currentPassword: new FormControl('', [Validators.required, Validators.minLength(1)]),
                newPassword: new FormControl('', [Validators.required, Validators.pattern(this.global.passwordRegex)]),
                confirmPassword: new FormControl('', [Validators.required])
            },
            {
                validators: [FieldAreEqualsValidator.verify('newPassword', 'confirmPassword')]
            }
        );
    }

    showPassword(): void {
        this.showPasswordForm = !this.showPasswordForm;

        if (!this.showPasswordForm) {
            this.initPasswordForm();
        }
    }

    private initEmailValueChange(): void {
        const emailFormControl = this.validateForm.get('email') as FormControl;
        const passwordFormControl = this.validateForm.get('password') as FormControl;
        emailFormControl.valueChanges.subscribe((newValue: string) => {
            let lockPassword = false;

            if (newValue !== this.currentEmailToShow && this.validateForm.get('email').valid) {
                this.showEmailPasswordField = true;
                if (passwordFormControl.touched) {
                    passwordFormControl.setValidators([Validators.required, Validators.minLength(5)]);
                }
                lockPassword = true;
            } else {
                this.showEmailPasswordField = false;
                passwordFormControl.setValidators([]);
                lockPassword = false;
            }

            this.disableOrEnablePassword(lockPassword);
        });
    }

    private disableOrEnablePassword(disable: boolean): void {
        this.initPasswordForm();
        this.showEmailPasswordField = disable;
    }

    /**
     * Update form values before submission.
     */
    private updateFormValues(): void {
        this.validateForm.value.isReachable = !this.validateForm.value.isReachable ? 0 : 1;
        this.validateForm.value.emailsOption = !this.validateForm.value.emailsOption ? 0 : 1;
    }

    /**
     * Check if email has changed.
     * @returns {boolean}
     */
    private isEmailChanged(): boolean {
        return this.validateForm.get('email').dirty && this.validateForm.get('email').value !== this.currentEmailToShow;
    }

    /**
     * Handle email change after account update.
     */
    private handleEmailChange(): void {
        this.newEmailRequested = true;
        this.initPasswordForm();
        this.showEmailPasswordField = false;
    }

    /**
     * Handle error during account update.
     * @param error - the error object
     */
    private handleAccountUpdateError(error: any): void {
        console.log('🚀 ~ file: account.component.ts:379 ~ AccountComponent ~ handleAccountUpdateError ~ error:', error);
        if (error.error && error.error.errors) {
            for (const err of error.error.errors) {
                Object.keys(err).forEach((key) => {
                    if (key === 'email') {
                        let errorMessage = 'ERRORS.EMAIL-TAKEN';
                        if (err.email === 'change_email_request_has_already_been_made') {
                            errorMessage = 'ERRORS.EMAIL-CHANGE-ALREADY-DONE';
                        }
                        this.notification.error(this.translate.instant(errorMessage));
                    }
                });
            }
        } else if (error.status === 400) {
            this.notification.error(this.translate.instant('ERRORS.WRONG-PASSWORD'));
        } else {
            throw error;
        }
    }

    /**
     * Handle invalid form.
     */
    private handleInvalidForm(): void {
        this.notification.error('Formulaire non valide');

        for (const control in this.validateForm.controls) {
            if (Object.prototype.hasOwnProperty.call(this.validateForm.controls, control)) {
                this.validateForm.controls[control].markAsDirty();
                this.validateForm.controls[control].updateValueAndValidity();
            }
        }
    }
}
