import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetEquipageById } from 'src/app/concepts/tours/enums/tour-equipage.enum';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';

@Component({
  selector: 'app-show-identification',
  templateUrl: './show-identification.component.html',
  styleUrls: ['./show-identification.component.scss']
})
export class ShowIdentificationComponent implements OnInit {

  @Input() backGroundColor: string;

  tourShow?: TourShow;

  equipages? = []

  @Input() set TourShow(tourShow: TourShow){
    this.tourShow = tourShow
    if(tourShow){
      this.equipages = this.tourShow.equipage ? this.tourShow.equipage.map( equipageId => this.getEquipageById(equipageId)) : [];
    }

  };



  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  getEquipageById(id): any {
    return this.translateService.instant(GetEquipageById(id));
  }

}
