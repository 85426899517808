<ng-container *ngIf="isReady">
  <app-page-title [title]="tour.getTranslatedProperty(this.translate.currentLang, 'name')" [subtitle]="tour.organization.getTranslatedProperty(this.translate.currentLang, 'name')" [image]="tour.organization.logo" [isWithImagePage]="true"> </app-page-title>

  <div class="container">
    <div class="row" >
      <div class="col-md-4">
        <div class="list-filters">
          <div class="list-filters__box">
            <ul nz-menu>
              <li nz-menu-item
                  class="list-filters__item"
                  [nzSelected]="openedTab == 'info'"
                  (click)="changeTab('info')">
                  {{ 'TOURS.INFORMATION-SPECTACLE' | translate}}
              </li>
              <li nz-menu-item
                  class="list-filters__item"
                  [nzSelected]="openedTab == 'conditions'"
                  (click)="changeTab('conditions')">
                  {{ 'TOURS.MODALITES-CONDITIONS' | translate}}
              </li>
              <li nz-menu-item
                  class="list-filters__item"
                  [nzSelected]="openedTab == 'dates'"
                  (click)="changeTab('dates')">
                  {{ 'TOURS.DATES-DISPONIBLES' | translate}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    <form
      id="tours-roseq"
      nz-form
      [formGroup]="formGroup"
      (ngSubmit)="doSubmit()"
    >

    <app-alert prefix class="mb-3 info description" *ngIf="description">
      <span [innerHTML]="description"></span>
    </app-alert>

    <div class="col-md-12">
      <ng-container *ngIf="openedTab == 'info'">
      <div style="color:black">
        <h2>
          <strong>{{ "TOURS.INFORMATION-SPECTACLE" | translate }}</strong>
        </h2>

        <div class="mt-2">
          <nz-form-item>
            <nz-form-control>
              <label class="mb-2"
                     for="showId">{{ 'FORM.SPECTACLE' | translate }} *</label>
              <nz-select id="showId"
                        *ngIf="!route.snapshot.data.tourShow"
                         name="showId"
                         nzPlaceHolder="{{'FORM.SELECTIONNER' | translate}}"
                         style="width: 100%; margin-bottom: 30px;"
                         required
                         formControlName="selectedShow">
                <nz-option *ngFor="let show of spectacles"
                           nzCustomContent
                           [nzLabel]="show.getTranslatedProperty(lang,'title')"
                           [nzValue]="show">
                  {{show.getTranslatedProperty(lang,'title')}}
                </nz-option>
              </nz-select>

              <input type="text"
                nz-input nzSize="default"
                [value]="show.getTranslatedProperty(lang,'title')"
                *ngIf="route.snapshot.data.tourShow && show"
                readonly="readonly"
                class="mb-3" />

              <nz-radio-group>
                <label formControlName="hasNoQCArtists"
                       nz-checkbox>{{ 'TOURS.ARTISTE-CANADIEN-HORS-QC' | translate }}</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>

          <div class="d-flex" style="align-items: center;">
            <label style="display:inline-flex;"
                   formControlName="hasEmergingArtists"
                   nz-checkbox>{{ 'TOURS.ARTISTE-EMERGENCE' | translate }}</label>
            <button
              nzShape="circle"
              nzTooltipTrigger="click"
              [nzTooltipTitle]="artisteEmergenceInfo"
              nzTooltipPlacement="rightBottom"
              nz-button
              nz-tooltip
              nzSize="small"
              class="tooltipBtn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="tooltipIcon">
                    <path d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321.13-.537.67-.868 1.207-.739s.868.669.739 1.206C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909-.126-.538.208-1.076.745-1.202.538-.127 1.076.207 1.202.744zM9.87 13c.41 0 .753.135 1.028.405.274.27.412.61.412 1.02 0 .41-.138.75-.412 1.02-.275.27-.618.405-1.028.405-.41 0-.747-.135-1.012-.405-.266-.27-.398-.61-.398-1.02 0-.41.132-.75.397-1.02.266-.27.603-.405 1.013-.405zm.165-8.5c.49 0 .927.072 1.313.218.385.145.712.35.982.614.27.266.475.578.615.938s.21.75.21 1.17c0 .3-.033.562-.098.787-.064.226-.152.43-.262.615-.11.185-.235.353-.375.503-.14.15-.29.295-.45.435-.15.12-.29.235-.42.345-.13.11-.248.225-.353.345-.104.12-.187.25-.247.39s-.09.305-.09.495v.18h-2.1v-.585c0-.27.045-.518.135-.742.09-.226.205-.428.345-.608.14-.18.295-.345.465-.495.17-.15.34-.29.51-.42.22-.17.415-.342.585-.518.17-.175.255-.377.255-.607 0-.26-.082-.485-.248-.675-.165-.19-.437-.285-.817-.285-.38 0-.675.11-.885.33-.21.22-.315.54-.315.96H6.75c0-1.09.293-1.927.878-2.512S9.014 4.5 10.035 4.5zM10 0c4.778 0 8.67 3.168 9.796 7.854.129.537-.202 1.077-.74 1.206-.536.13-1.076-.202-1.205-.739C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367c-.126.537-.664.87-1.202.745C.4 8.985.066 8.447.191 7.909 1.3 3.194 5.202 0 10.001 0z"/>
                </svg>
            </button>
            <ng-template #artisteEmergenceInfo>
              <span>{{
                "TOURS.ARTISTE-EMERGENCE-INFORMATION" | translate
              }}</span>
            </ng-template>
          </div>
        </div>
      </div>


      <div style="color:black">
        <div class="mt-2">
          <nz-form-item>
            <nz-form-control>
              <label class="cachetsListTitle">
                {{ "TOURS.CACHET-INCLUT" | translate }}
              </label>
              <div class="cachets">
                <nz-radio-group class="cachets-col">

                  <label class="d-block ml-0 mb-2"
                         formControlName="directeur"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-0' | translate }}</label>
                  <label class="d-block ml-0 mb-2"
                         formControlName="sonorisateur"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-1' | translate }}</label>
                  <label class="d-block ml-0 mb-2"
                         formControlName="eclaragist"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-2' | translate }}</label>
                </nz-radio-group>
                <nz-radio-group class="cachets-col">
                  <label class="d-block ml-0 mb-2"
                         formControlName="equipmentSonore"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-3' | translate }}</label>
                  <label class="d-block ml-0 mb-2"
                         formControlName="equipmentEclarage"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-4' | translate }}</label>
                  <label class="d-block ml-0 mb-2"
                         formControlName="premierePartie"
                         nz-checkbox>{{ 'TOURS.CACHET-INCLUT-5' | translate }}</label>

                </nz-radio-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <div class="d-flex align-items-center">
                <label
                >{{
                  "TOURS.NOMBRE-PERSONNE-SUR-SCENE"
                    | translate
                  }} *
                </label>
              </div>
              <div class="personsOnStageInput">
                <input type="number"
                       nz-input
                       formControlName="personsOnStage">
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <div class="d-flex align-items-center">
                <label
                  >{{
                    "TOURS.EXIGENCES-TECHNIQUES-FINANCIERE-PARTICULIERES"
                      | translate
                  }}
                </label>
                <button
                  nzShape="circle"
                  nzTooltipTrigger="click"
                  [nzTooltipTitle]="extraRequirementsInfo"
                  nzTooltipPlacement="rightBottom"
                  nz-button
                  nz-tooltip
                  nzSize="small"
                  class="ml-2 tooltipBtn"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="tooltipIcon">
                        <path d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321.13-.537.67-.868 1.207-.739s.868.669.739 1.206C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909-.126-.538.208-1.076.745-1.202.538-.127 1.076.207 1.202.744zM9.87 13c.41 0 .753.135 1.028.405.274.27.412.61.412 1.02 0 .41-.138.75-.412 1.02-.275.27-.618.405-1.028.405-.41 0-.747-.135-1.012-.405-.266-.27-.398-.61-.398-1.02 0-.41.132-.75.397-1.02.266-.27.603-.405 1.013-.405zm.165-8.5c.49 0 .927.072 1.313.218.385.145.712.35.982.614.27.266.475.578.615.938s.21.75.21 1.17c0 .3-.033.562-.098.787-.064.226-.152.43-.262.615-.11.185-.235.353-.375.503-.14.15-.29.295-.45.435-.15.12-.29.235-.42.345-.13.11-.248.225-.353.345-.104.12-.187.25-.247.39s-.09.305-.09.495v.18h-2.1v-.585c0-.27.045-.518.135-.742.09-.226.205-.428.345-.608.14-.18.295-.345.465-.495.17-.15.34-.29.51-.42.22-.17.415-.342.585-.518.17-.175.255-.377.255-.607 0-.26-.082-.485-.248-.675-.165-.19-.437-.285-.817-.285-.38 0-.675.11-.885.33-.21.22-.315.54-.315.96H6.75c0-1.09.293-1.927.878-2.512S9.014 4.5 10.035 4.5zM10 0c4.778 0 8.67 3.168 9.796 7.854.129.537-.202 1.077-.74 1.206-.536.13-1.076-.202-1.205-.739C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367c-.126.537-.664.87-1.202.745C.4 8.985.066 8.447.191 7.909 1.3 3.194 5.202 0 10.001 0z"/>
                    </svg>
                </button>
                <ng-template #extraRequirementsInfo>
                  <span>{{
                    "TOURS.EXIGENCES-TECHNIQUES-FINANCIERE-PARTICULIERES-INFORMATION"
                      | translate
                  }}</span>
                </ng-template>
              </div>

              <textarea row="4" nz-input formControlName="extraRequirements" maxlength="1000"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
        <button nz-button class="btn btn--default mt-4"
                (click)="changeTab('conditions')"
                type="button"
                [disabled]="!formGroup.controls.selectedShow.valid || !formGroup.controls.personsOnStage.valid"
                [class.disabled]="!formGroup.controls.selectedShow.valid || !formGroup.controls.personsOnStage.valid"
                *ngIf="!route.snapshot.data.tourShow">
          {{ "CONTINUER" | translate }}
        </button>
        <button type="button" nz-button class="btn btn--default mt-4"
          (click)="doSubmit()"
          [disabled]="!formGroup.valid"
          [class.disabled]="!formGroup.valid"
          *ngIf="route.snapshot.data.tourShow && show">
          {{ (tourShowId ? "FORM.ENREGISTRER" : "FORM.SOUMETTRE") | translate }}
        </button>
      </ng-container>

      <!-- CONDITIONS -->
      <ng-container *ngIf="openedTab == 'conditions'" [formGroup]="conditionsGroup">
      <div class="conditionsTab" style="color:black">
        <h2>
          <strong>{{ "TOURS.MODALITES-CONDITIONS" | translate }}</strong>
        </h2>

      <p class="sectionTitle" class="mb-2">Type de cachet *</p>

      <div class="mt-2">
        <nz-select id="cachetType"
                  name="cachetType"
                  nzPlaceHolder="{{'FORM.SELECTIONNER' | translate}}"
                  style="width: 100%; margin-bottom: 30px;"
                  required
                  formControlName="cachetType"
                  (ngModelChange)="cachetTypeChange()">
          <nz-option [nzValue]="cachetTypes.FIXE"
                     nzLabel="{{ 'TOURS.CACHET-FIXE' | translate }}"></nz-option>
          <nz-option [nzValue]="cachetTypes.DEGRESSIF"
                     nzLabel="{{ 'TOURS.CACHET-DEGRESSIF' | translate }}"></nz-option>
          <nz-option [nzValue]="cachetTypes.GROUPE"
                     nzLabel="{{ 'TOURS.CACHET-GROUPE' | translate }}"></nz-option>
        </nz-select>
      </div>

      <p class="sectionTitle" *ngIf="conditionsGroup.controls.cachetType.valid">Tarification</p>
      <div>
        <div *ngIf="conditionsGroup.controls.cachetType.value === cachetTypes.FIXE" [formGroup]="cachetFixeGroup">
          <nz-form-item>
            <nz-form-control>
              <label for="showsFee"
              >{{ "TOURS.CACHET-FIXE" | translate }} *
                <div class="labelInputBox">
                  <input
                    id="showsFee"
                    formControlName="showsFee"
                    type="number"
                    min="0"
                    required
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngIf="conditionsGroup.controls.cachetType.value === cachetTypes.DEGRESSIF" [formGroup]="cachetDegressifGroup">
          <nz-form-item>
            <nz-form-control>
              <label for="showsFeeDiscount1"
              >{{ "TOURS.CACHET-1-REPRESENTATION" | translate }} *
                <div class="labelInputBox">
                  <input
                    id="showsFeeDiscount1"
                    formControlName="showsFeeDiscount1"
                    type="number"
                    min="0"
                    required
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="showsFeeDiscount2"
              >{{ "TOURS.CACHET-2-REPRESENTATION" | translate }}
                <div class="labelInputBox">
                  <input
                    id="showsFeeDiscount2"
                    formControlName="showsFeeDiscount2"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="showsFeeDiscount3"
              >{{ "TOURS.CACHET-3-REPRESENTATION" | translate }}
                <div class="labelInputBox">
                  <input
                    id="showsFeeDiscount3"
                    formControlName="showsFeeDiscount3"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="showsFeeDiscount4"
              >{{ "TOURS.CACHET-4-REPRESENTATION" | translate }}
                <div class="labelInputBox">
                  <input
                    id="showsFeeDiscount4"
                    formControlName="showsFeeDiscount4"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngIf="conditionsGroup.controls.cachetType.value === cachetTypes.GROUPE" [formGroup]="cachetGroupeGroup">
          <nz-form-item>
            <nz-form-control>
              <label for="shows3Fee"
              >{{ "TOURS.CACHET-FIXE-1-3" | translate }} *
                <div class="labelInputBox">
                  <input
                    id="shows3Fee"
                    formControlName="shows3Fee"
                    type="number"
                    min="0"
                    required
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="shows4To6Fee"
              >{{ "TOURS.CACHET-FIXE-4-6" | translate }}
                <div class="labelInputBox">
                  <input
                    id="shows4To6Fee"
                    formControlName="shows4To6Fee"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="shows7To9Fee"
              >{{ "TOURS.CACHET-FIXE-7-9" | translate }}
                <div class="labelInputBox">
                  <input
                    id="shows7To9Fee"
                    formControlName="shows7To9Fee"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="shows10PlusFee"
              >{{ "TOURS.CACHET-FIXE-10+" | translate }}
                <div class="labelInputBox">
                  <input
                    id="shows10PlusFee"
                    formControlName="shows10PlusFee"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
          <p class="sectionTitle">Autres modalités</p>
          <nz-form-item>
            <nz-form-control>
              <label for="revenuePercentage"
                >{{ "TOURS.POURCENTAGE" | translate }}
                <div class="labelInputBox">
                  <input
                    id="revenuePercentage"
                    formControlName="revenuePercentage"
                    type="number"
                    min="0"
                    max="50"
                  />
                  <span>%</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <label for="broadcastExpenses"
                >{{ "TOURS.DEPENSES-DIFFUSEURS" | translate }}
                <div class="labelInputBox">
                  <input
                    id="broadcastExpenses"
                    formControlName="broadcastExpenses"
                    type="number"
                    min="0"
                  />
                  <span>$</span>
                </div>
              </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <div class="HelpCheckbox">
                <span class="tooltipContainer">{{ 'TOURS.AIDE-TOURNEE' | translate }}
                  <button
                    nzShape="circle"
                    nzTooltipTrigger="click"
                    [nzTooltipTitle]="isAvailableAllTimeInfo"
                    nzTooltipPlacement="rightBottom"
                    nz-button
                    nz-tooltip
                    nzSize="small"
                    class="ml-2 tooltipBtn"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="tooltipIcon">
                        <path d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321.13-.537.67-.868 1.207-.739s.868.669.739 1.206C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909-.126-.538.208-1.076.745-1.202.538-.127 1.076.207 1.202.744zM9.87 13c.41 0 .753.135 1.028.405.274.27.412.61.412 1.02 0 .41-.138.75-.412 1.02-.275.27-.618.405-1.028.405-.41 0-.747-.135-1.012-.405-.266-.27-.398-.61-.398-1.02 0-.41.132-.75.397-1.02.266-.27.603-.405 1.013-.405zm.165-8.5c.49 0 .927.072 1.313.218.385.145.712.35.982.614.27.266.475.578.615.938s.21.75.21 1.17c0 .3-.033.562-.098.787-.064.226-.152.43-.262.615-.11.185-.235.353-.375.503-.14.15-.29.295-.45.435-.15.12-.29.235-.42.345-.13.11-.248.225-.353.345-.104.12-.187.25-.247.39s-.09.305-.09.495v.18h-2.1v-.585c0-.27.045-.518.135-.742.09-.226.205-.428.345-.608.14-.18.295-.345.465-.495.17-.15.34-.29.51-.42.22-.17.415-.342.585-.518.17-.175.255-.377.255-.607 0-.26-.082-.485-.248-.675-.165-.19-.437-.285-.817-.285-.38 0-.675.11-.885.33-.21.22-.315.54-.315.96H6.75c0-1.09.293-1.927.878-2.512S9.014 4.5 10.035 4.5zM10 0c4.778 0 8.67 3.168 9.796 7.854.129.537-.202 1.077-.74 1.206-.536.13-1.076-.202-1.205-.739C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367c-.126.537-.664.87-1.202.745C.4 8.985.066 8.447.191 7.909 1.3 3.194 5.202 0 10.001 0z"/>
                    </svg>
                  </button>
                </span>
                <nz-switch formControlName="isHelpNeeded"></nz-switch>
              </div>
              <ng-template #isAvailableAllTimeInfo>
                <span>{{ "TOURS.AIDE-TOURNEE-INFORMATION" | translate }}</span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
      </div>
        <button type="button" nz-button class="btn btn--default mt-4"
          (click)="changeTab('dates')"
          [disabled]="!conditionsGroup.valid"
          [class.disabled]="!conditionsGroup.valid"
          *ngIf="!route.snapshot.data.tourShow">
          {{ "CONTINUER" | translate }}
        </button>
        <button type="button" nz-button class="btn btn--default mt-4"
          (click)="doSubmit()"
          [disabled]="!formGroup.valid"
          [class.disabled]="!formGroup.valid"
          *ngIf="route.snapshot.data.tourShow && show">
          {{ (tourShowId ? "FORM.ENREGISTRER" : "FORM.SOUMETTRE") | translate }}
        </button>
      </ng-container>


      <ng-container *ngIf="openedTab == 'dates'">
      <div style="color:black">
        <h2>
          <strong>{{ "TOURS.DATES-DISPONIBLES" | translate }}</strong>
        </h2>
        <div class="mt-2">
          <nz-form-item>
            <nz-form-control>
              <nz-radio-group
                class="input-radioBtn"
                [(ngModel)]="radioDateValue"
                [ngModelOptions]="{ standalone: true }"
              >
                <label
                  nz-radio
                  nzValue="roseqRadioAllTime"
                  class="input-radioBtn__radio d-block mb-2"
                  >{{ "TOURS.DISPONIBILITE-TOUT-TEMPS" | translate }}</label
                >
                <label
                  nz-radio
                  nzValue="roseqRadioValidityDate"
                  class="input-radioBtn__radio d-block mb-2"
                >
                  {{ "TOURS.DATES-DEFINIES" | translate }}
                </label>
              </nz-radio-group>
              <div class="datePickerContainer"
              *ngIf="radioDateValue == 'roseqRadioValidityDate'">
                <p>Bloc 1</p>
                <div class="datePickerWithLabels">
                  <div class="DatePickerLabels">
                    <p>Date de début</p>
                    <p>Date de fin</p>
                  </div>
                  <nz-range-picker
                    formControlName="toursRoseqDateRangeBloc1"
                    class="blocDates"
                    nzAllowClear="true"
                    nzFormat="yyyy/MM/dd"
                    nzMode='date'
                    [nzShowTime]='false'
                    [nzDisabledTime]='true'
                    *ngIf="radioDateValue == 'roseqRadioValidityDate'"
                  >
                  </nz-range-picker>
                </div>
              </div>
              <div class="datePickerContainer" *ngIf="radioDateValue == 'roseqRadioValidityDate'">
                <p>Bloc 2</p>
                <div class="datePickerWithLabels">
                  <div class="DatePickerLabels">
                      <p>Date de début</p>
                      <p>Date de fin</p>
                    </div>
                  <nz-range-picker
                  formControlName="toursRoseqDateRangeBloc2"
                  class="blocDates"
                  nzAllowClear="true"
                  nzFormat="yyyy/MM/dd"
                  nzMode='date'
                  [nzShowTime]='false'
                  [nzDisabledTime]='true'
                  *ngIf="radioDateValue == 'roseqRadioValidityDate'"
                >
                </nz-range-picker>
                </div>
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
        <button type="button" nz-button class="btn btn--default mt-4"
          (click)="doSubmit()"
          [disabled]="!formGroup.valid"
          [class.disabled]="!formGroup.valid">
          {{ (tourShowId ? "FORM.ENREGISTRER" : "FORM.SOUMETTRE") | translate }}
        </button>
      </ng-container>

    </div>
</form>
    </div>
  </div>

</ng-container>
