<ng-container *ngIf="tourShow">
<div class="pb-5 identifications" [ngStyle]='{"background-color": backGroundColor }'>
  <div class="container" >
    <div nz-row
    nzGutter="16">
    <div nz-col
    nzMd="12" class="p-0">
    <h3 class="mt-5 mb-3 ml-2">{{ 'TOURS.IDENTIFICATION-ARTISTE' | translate }} </h3>
    <div nz-col
    nzMd="12">
        <hr class="bg-neutral clr-neutral" />
        <p class="page-template-labels mb-0">{{ 'TOURS.ARTISTE-CANADIEN-HORS-QC' | translate }}</p>
        <p class="page-template-text mb-1">{{ tourShow.hasNoQCArtists ? 'Oui' : 'Non'  }}</p>
      </div>
      <div nz-col
          nzMd="12">
        <hr class="bg-neutral clr-neutral" />
        <p class="page-template-labels mb-0">{{ 'TOURS.ARTISTE-EMERGENCE' | translate }}</p>
        <p class="page-template-text mb-1">{{ tourShow.hasEmergingArtists ? 'Oui' : 'Non' }}</p>
      </div>
        </div>
        <div nz-col
          nzMd="12" class="p-0">
          <h3 class="mt-5 mb-3 ml-2">{{ 'TOURS.IDENTIFICATION-SPECTACLE' | translate }}</h3>
      <div nz-col
      nzMd="12">
        <hr class="bg-neutral clr-neutral" />
        <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-INCLUT' | translate }}</p>
        <p class="page-template-text multi mb-1">
          <!-- EQUIPAGE -->
          <span *ngFor="let equipageMember of equipages" class=""> {{ equipageMember | translate }}</span>
        </p>
      </div>
      <div nz-col
          nzMd="12">
        <hr class="bg-neutral clr-neutral" />
        <p class="page-template-labels mb-0">{{ 'TOURS.EXIGENCES-TECHNIQUES-FINANCIERE-PARTICULIERES' | translate }}</p>
        <p class="page-template-text mb-1">{{ tourShow.extraRequirements ? tourShow.extraRequirements : 'NA' }}</p>
      </div>
          <div nz-col
               nzMd="12">
            <hr class="bg-neutral clr-neutral" />
            <p class="page-template-labels mb-0">{{ 'TOURS.PERSONNES-SUR-SCENE' | translate }}</p>
            <p class="page-template-text mb-1">{{ tourShow.personsOnStage ? tourShow.personsOnStage : 'NA' }}</p>
          </div>
        </div>
    </div>
  </div>
</div>
</ng-container>

