import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../../concepts/account/services/account.service';
import { filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../concepts/account/services/auth/auth.service';
import { NotificationsService } from 'src/app/concepts/notifications/services/notifications.service';
import { Notification } from '../../concepts/notifications/model/notifications.model';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/shared/model/list-item.model';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { Observable, Subject } from 'rxjs';
import { Organization } from '@app/concepts/organization/model/organization.model';
import { PxUser } from '@app/concepts/account/model/project-x-user';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {
    isDisabled = false;
    isReady = false;
    userHasBeenInvited = false;
    displayNotification = false;

    subscriptions: any;
    currentLang: string;
    linkToAllNotification: string = `/notifications`;

    notifications: Notification[] = [];
    pagination: Pagination;
    ctxOrganizationId: number;
    defaultNotificationFilters = [];
    userOrganization$: Observable<Organization[]>;
    readonly userObservable$: Observable<PxUser> = this.authService.userObservable$;
    private destroyed: Subject<void> = new Subject();
    constructor(
        private httpClient: HttpClient,
        private notificationsService: NotificationsService,
        private toursService: ToursService,
        private authService: AuthService,
        private translate: TranslateService,
        private accountService: AccountService
    ) {
        this.currentLang = this.translate.currentLang;
        this.linkToAllNotification += `/${this.currentLang}${this.linkToAllNotification}`;
    }

    ngOnInit(): void {
        this.userObservable$
            .pipe(
                // Filter out falsy user values
                filter(user => !!user),
                // Switch to a new observable based on the user's organizations
                switchMap(async (user: PxUser): Promise<Organization[]> => {
                    return await this.accountService.getUserOrganizations(user.id).toPromise()
                }),
                // Map the organizations to a specific organization or the first one
                map((organizations: Organization[]) => {
                    this.isDisabled = true;
                    if (!organizations || !organizations.length) {
                        return null;
                    }
                    this.userHasBeenInvited = true;
                    this.ctxOrganizationId = this.accountService.getCurrentCtxOrganizationId();
                    const index = organizations.findIndex(x => x.id === this.ctxOrganizationId);
                    return organizations[ index > -1 ? index : 0 ];
                }),
                // Map the organization to default notification filters and trigger a function
                map((response: Organization | null) => {
                    this.defaultNotificationFilters = [];
                    if (response) {
                        this.defaultNotificationFilters = [ { field: 'contextOrgId', value: this.ctxOrganizationId } ];
                    }
                    this.getAllNotifications();
                    this.setReady();
                }),
                // Take until the component is destroyed to prevent memory leaks
                takeUntil(this.destroyed)
            )
            .subscribe();

        this.initPagination();
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    initPagination(): void {
        this.pagination = new Pagination();
        this.pagination.limit = 8;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    async getAllNotifications(): Promise<void> {
        this.notificationsService
            .getAllNotifications([ ...this.defaultNotificationFilters ], this.pagination)
            .pipe(
                filter((results: Notification[]) => {
                    if (!!results && !!results.length) {
                        return true;
                    }
                    this.notifications = [];
                    return false;
                }),
                switchMap(async (results: Notification[]) => await this.getUpdatedNotifications(results)),
                finalize(() => {
                    this.setReady();
                    // Update Notifications count
                    this.notificationsService.triggerNotificationCountUpdate();
                }),
                takeUntil(this.destroyed)
            )
            .subscribe((data: Notification[]) => this.notifications = data);
    }

    public archiveNotifications(id?: number): void {
        this.notificationsService.archiveNotifications(id).pipe(takeUntil(this.destroyed)).subscribe(() => this.getAllNotifications());
    }

    private setReady(): void {
        this.isDisabled = false;
        this.isReady = true;
    }

    private getUpdatedNotifications = async (data: Notification[]) => {
        for (let idx of data.keys()) {
            let notification = data[ idx ];
            if (!!notification.tourId) {
                data[ idx ] = await this.getNotificationWithTourOrg(notification);
            }
        }
        return data;
    };

    private getNotificationWithTourOrg = async (notification: Notification): Promise<Notification> => {
        return new Notification({
            ...notification,
            tourAdmin: (await this.toursService.getTourByIdForNotifications(notification.tourId).toPromise()).organization
        });
    }
}
