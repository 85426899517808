<ng-container *ngIf="isReady">

    <ng-container *ngIf="videoType !== undefined; else noContent">
        <!-- YOUTUBE PLAYER -->
        <ng-container *ngIf="videoType == 'youtube'">
            <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe [src]="youtubeVideo" style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    title="youtubeVideo"></iframe>
            </div>
            <ng-container *ngTemplateOutlet="footer; context: {$implicit: src, password: password}"></ng-container>
        </ng-container>

        <!-- VIMEO PLAYER -->
        <ng-container *ngIf="videoType == 'vimeo'">
            <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe [src]="vimeoVideo" style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    allow="autoplay; fullscreen" allowfullscreen title="vimeoVideo"></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <ng-container *ngTemplateOutlet="footer; context: {$implicit: src, password: password}"></ng-container>
        </ng-container>

        <!-- OTHERS VIDEOS -->
        <ng-container *ngIf="videoType !== 'youtube' && videoType !== 'vimeo'">
            <div class="noPlayerBox">
                <ng-container *ngTemplateOutlet="footer; context: {$implicit: src, password: password}"></ng-container>
            </div>
        </ng-container>
    </ng-container>


    <!-- NO VIDEO -->
    <ng-template #noContent>
        <p>{{'AUCUN-LIEN-VIDEO' | translate }}</p>
    </ng-template>

</ng-container>

<ng-template #footer let-src let-password="password">
    <div class="mt-2">

        <a class="d-flex align-items-center u-link-primary" [href]="src | externalLink" target="_blank">
            <i nz-icon aria-hidden="true" nzType="youtube" nzTheme="outline"></i>
            <span class="ml-3"> {{'OTHERS-VIDEO-LINK' | translate}} </span>
        </a>
        <ng-container *ngIf="password">
            <span>&#9135;</span>
            <span>{{'MOT-DE-PASSE' | translate}}: <strong>{{password}}</strong></span>
        </ng-container>
    </div>
</ng-template>
