import { Injectable, OnDestroy } from '@angular/core';
import { MainService } from '../../../shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../_configs/globals';
import { BehaviorSubject, Observable } from 'rxjs';
import { IStandRequest, IStandResponse, Stand } from '../model/stand.model';
import { Pagination, SelectedFilter } from '../../../shared/model/list-item.model';
import { AccountService } from '../../account/services/account.service';
import { IUpdatedResponse } from '@app/shared/model/response.model';

const ORG_ID_QS_PREFIX = '?organizationId=';

@Injectable({
    providedIn: 'root'
})
export class StandService extends MainService {
    constructor(protected httpClient: HttpClient, protected accountService: AccountService, protected globals: Globals) {
        // ratacher à  l'organisation courante
        super(httpClient, globals);
    }

    createStand(stand: Stand): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.main;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.post(endpoint, this.cleanUpNullValues(stand));
    }

    createStandRange(standRange: any): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.all;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.post(endpoint, standRange);
    }

    updateStand(payload: IStandRequest): Observable<IUpdatedResponse> {
        let endpoint = this.uri + this.globals.endpoints.stand.main;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();
        return this.httpClient.put<IUpdatedResponse>(endpoint, { ...this.cleanUpNullValues(payload), contextOrganizationId: this.accountService.getCurrentCtxOrganizationId() });
    }

    deleteStand(standId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.main + '/' + standId;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.delete(endpoint);
    }

    getStands(meetingId?: number, filters?: SelectedFilter[], pagination?: Pagination): Observable<IStandResponse> {
        let endpoint = this.uri + this.globals.endpoints.stand.all;

        if (meetingId || filters || pagination) {
            endpoint += '?';
        }

        if (meetingId) {
            endpoint += 'meetingId=' + meetingId;
            endpoint += filters || pagination ? '&' : '';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get<IStandResponse>(endpoint);
    }
}
